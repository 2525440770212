// Icons
import BTC from "./BTC.svg";
import ETH from "./ETH.svg";
import LTC from "./LTC.svg";
import CAKE from "./CAKE.svg";
import BNB from "./BNB.svg";
import USDT from "./USDT.svg";
import CYT from "./CYT.svg";
import XMR from "./XMR.svg";
import DOGE from "./DOGE.svg";
import MATIC from "./MATIC.svg";
import XRP from "./XRP.svg";
import SHIB from "./SHIB.svg";
import BBC from "./BBC.svg";

interface CurrencyIcons {
    [key: string]: string; // This tells TypeScript that any string key returns a string
}

export const currencyIcons: CurrencyIcons = {
    BTC,
    ETH,
    LTC,
    CAKE,
    BNB,
    USDT,
    CYT,
    MATIC,
    XMR,
    DOGE,
    XRP,
    SHIB,
    BBC
};
